import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowGraphic from '../assets/arrow-left.svg';
import FetchFailed from "../components/FetchFailed"
import { useNavigate } from 'react-router'


function ViewMessage() {
    const navigate = useNavigate();

    let timer;

    window.onload = timerReset;
    document.onkeypress = timerReset;
    document.onmousemove = timerReset;
    document.onmousedown = timerReset;
    document.ontouchstart = timerReset;
    document.onclick = timerReset;
    document.onscroll = timerReset;

    function timerElapsed() {
        window.location = '/';
    };
    
    function timerReset() {
        clearTimeout(timer);
        timer = setTimeout(timerElapsed, 10 * 60 * 5000); // 10 mins
    }

    let { msg } = useParams();

    useEffect(() => {
        getMessage()
    }, [])

    const [message, setMessage] = useState([]);
    const [printing, setPrinting] = useState([]);
    const [done, setDone] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [fetchFailed, setFetchFailed] = useState(false);
    function getMessage() {
        fetch(process.env.REACT_APP_API + '/msg/' + msg, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('authJWT')}`
            }
        })
        .then(res => res.json())
        .then(d =>{
            setMessage(d.image)
            setLoading(false);
            setFetchFailed(false);
        }).catch((err) => {
            console.log(err)
            setFetchFailed(true);
            setLoading(false);
        })
    }

    function printImage(img) {
        if(!printing.includes(img.uuid) && !done.some((i) => i.uuid === img.uuid)) {
          setPrinting([...printing, img.uuid])
          setTimeout(() => {
            fetch(process.env.REACT_APP_API + '/printImage?img=' + img.uuid, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('authJWT')}`
                }
            })
            .then(res => res.json())
            .then(d =>{
                setPrinting(printing.filter(item => item !== img.uuid))
                setDone([...done, {uuid: img.uuid, error: false}])
            }).catch((err) => {
                console.log(err)
                setPrinting(printing.filter(item => item !== img.uuid))
                setDone([...done, {uuid: img.uuid, error: true}])
            })
          }, 2500);
        }
    }

    return (
        <div>
            {!fetchFailed && !isLoading &&
            <div className="message-main">
                    <div className="back-div" onTouchStart={() => navigate('/')} onClick={() => navigate('/')}>
                        <img src={ArrowGraphic} className="back-arrow" alt="Back"></img>
                    </div>
                    <div className="main-container">
                        <p className="title">{message.title}</p>
                        <p className="desc">{message.message}</p>
                        <img className="avatar" src={message.author?.avatar} alt="Avatar" />
                        <p className="main-text">{message.author?.name}</p>
                        <p className="main-text">{new Date(message.sentAt).toLocaleDateString("de-De", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) } | {((new Date(message.sentAt).getHours() < 10)?"0":"") + new Date(message.sentAt).getHours() +":"+ ((new Date(message.sentAt).getMinutes() < 10)?"0":"") + new Date(message.sentAt).getMinutes() } Uhr</p>
                    </div>
                    {message.images !== [] && message.images.map((img, i) => 
                    <div className="image-container">
                        <img className="image" src={img.url} alt="Image" />
                        <div className="print-btn center-btn" onTouchStart={() => printImage(img)} onClick={() => printImage(img)}>
                          <p>{done.some((i) => (i.uuid === img.uuid && i.error === false)) ? "Bild wird gedruckt!" : done.some((i) => (i.uuid === img.uuid && i.error === true)) ? "Fehler beim drucken!" : printing.some((i) => i === img.uuid) ? "Bitte Warten..." : "Drucken"}</p>
                        </div>
                    </div>
                    )}
            </div>
            }
            {fetchFailed &&
                <FetchFailed />
            }
            <div className="fetch-failed">
                <br></br>
                <br></br>
                <br></br>
                <h2>{isLoading === true ? 'Lade Nachicht... Bitte Warten!' : 'Ende'}</h2>
                <br></br>
            </div>
        </div>
    )
}

export default ViewMessage;